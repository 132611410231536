import { compose } from 'ramda'
import { withAnalytics } from 'hocs/withAnalytics'
import withAuth from 'hocs/withAuth'
import { withCookies } from 'hocs/withCookies'
import { withGql } from 'hocs/withGql'
import { withPresetSearch } from 'hocs/withPresetSearch'
import withVertical from 'hocs/withVertical'
import { JobsBoardPage } from 'containers/jobs-board/page'

export default compose(
    withGql({
        normalizedCacheOptions: {
            keys: {
                Affiliate: (c: any) => {
                    return c.url
                },
            },
        },
    }),
    withAuth({
        isPublicPage: true,
    }),
    withVertical,
    withCookies,
    withAnalytics({
        pageNameForAnalytics: 'Jobsboard Search',
        pageVersionForAnalytics: '2',
    }),
    withPresetSearch({ entity: 'job' }),
)(JobsBoardPage)
